<template>
  <v-app>

    <v-app-bar
      app
      color="primary"
      dark
      elevate-on-scroll
    >
    <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Nutri-Calc</v-toolbar-title>
      <span class=".d-flex d-sm-none">XS</span>
      <span class="d-none d-sm-flex d-md-none">SM</span>
      <span class="d-none d-md-flex d-lg-none">MD</span>
      <span class="d-none d-lg-flex d-xl-none">LG</span>
      <span class="d-none d-xl-flex">XL</span>
      <v-spacer></v-spacer>

      <v-btn
        href="https://github.com/fsirigu/NutriCalc/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>


      <v-navigation-drawer
      v-model="drawer"
      absolute
      bottom
      temporary
    >
    <!-- -->
  </v-navigation-drawer>

    <v-main>    
       <InfoNutri @envoi-cas-general="calculCasGeneral"/>
        <NutriScore :densiteEnergetique="densiteenergetique" :graissesSaturees="acidesgrassat" :sucres="sucres" :sodium="sodium"
                  :fibres="fibres" :proteines="proteines" :percentPos="percentPos" :type="type"/>
    </v-main>
     <v-footer >
    <v-col
      class="text-center"
      cols="12"
    >
      {{ new Date().getFullYear() }} — <strong>Nutri-Calc</strong>
    </v-col>
  </v-footer>
  </v-app>
</template>

<script>

 import InfoNutri from './components/InfoNutri.vue';
 import NutriScore from './components/NutriScore.vue'

export default {
  name: 'App',

  components: {
    InfoNutri,NutriScore
  },  
  methods : {
    calculCasGeneral(nrj,gras,sucre,sodium, percentPos, fibres, proteines, type) {
   
      this.densiteenergetique=nrj;
      this.acidesgrassat=gras;
      this.sucres=sucre;
      this.sodium=sodium;
      this.fibres=fibres,
      this.percentPos=percentPos,
      this.proteines=proteines,
      this.type=type
    }
  },
   data() {
            return {
              drawer: false,
              group: null,
                densiteenergetique:0,
                matieresgrasses : 0,
                acidesgrassat : 0,
                glucides:0,
                sucres:0,
                proteines :0,
                sodium : 0,
                percentPos:0,
                fibres:0,
                type : 0,
            }
   },
   watch: {
      group () {
        this.drawer = false
      },
    },
   
};
</script>