<template>
  <v-container>
    <v-form ref="form"  v-model="valid">
      <v-row>
        <v-col cols="12" md="6" >
          <v-text-field
            v-model.number="densiteenergetique"
            :label="'Densité énergétique (pour 100'+unit+')'"
            type="number"
            min="0"
            :rules="zRules"
            max="10000"
            suffix="kJ"
            @change="envoyerDonneesCasGeneral()"
          />   
          <v-text-field
            v-model.number="acidesgrassat"
            :label="'Acides gras saturés (pour 100'+unit+')'"
            type="number"
            :rules="zcRules"
            min="0"
            max="100"
            suffix="g"
            @change="envoyerDonneesCasGeneral()"
          />     
          <v-text-field
            v-model.number="sucres"
            :label="'Sucres (pour 100'+unit+')'"
            type="number"
            :rules="zcRules"
            min="0"
            max="100"
            suffix="g"
            @change="envoyerDonneesCasGeneral()"
          />
          <v-text-field
            v-model.number="sel"
            :label="'Sel (pour 100'+unit+')'"
            type="number"
            min="0"
            :rules="zcRules"
            max="100"
            suffix="g"
            @change="envoyerDonneesCasGeneral()"
          />
        </v-col>
        <v-col md="6" cols="12">
          <v-text-field
            v-model.number="proteines"
            :label="'Protéines (pour 100'+unit+')'"
            type="number"
            min="0"
            :rules="zcRules"
            max="100"
            suffix="g"
            @change="envoyerDonneesCasGeneral()"
          />
          <v-text-field
            v-model.number="fibres"
            :label="'Fibres (pour 100'+unit+')'"
            type="number"
            min="0"
            :rules="zcRules"
            max="100"
            suffix="g"
            @change="envoyerDonneesCasGeneral()"
          />
          <v-text-field
            v-model.number="percentPos"
            label="Fruits, légumes, légumineuses, fruits à coque, huiles de colza, de noix et d'olive"
            type="number"
            min="0"
            :rules="zcRules"
            max="100"
            suffix="%"
            @change="envoyerDonneesCasGeneral()"
          />
        </v-col>
        <v-col md="6" sm="12"> </v-col>
      </v-row>
       <v-row>
        <v-col md="6" sm="12"> 
          <v-select
          :items="types"
          item-text="label"
          item-value="value"
          label="Type d'aliment"
          v-model="type"
          dense
          @change="envoyerDonneesCasGeneral()"
        ></v-select>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      densiteenergetique: 0,
      acidesgrassat: 0,
      sucres: 0,
      proteines: 0,
      sel: 0,
      fibres: 0,
      percentPos: 0,
      type : 0 ,
      types : [ {label:'Général', value: 0},{label:'Fromage', value:1},{label:'Boisson',value:2}],
      zcRules: [    
        (v) => (v>=0 && v <= 100) || "La valeur doit êtrecomprise entre 0 et 100",      
      ],
       zRules: [        
        (v) => (v >= 0) || "La valeur doit être supérieure à 0",
      ],
    };
  },
  computed: {
    sodium() {
      //sodium (mg) = sel(g) * 1000 / 2.5
      return this.sel * 400;
    },
    unit() {
      if (this.type==2) 
        return 'ml'; else return 'g';
    }
  },
  methods: {
    envoyerDonneesCasGeneral() {
     if (this.validateField()) {
      this.$emit(
        "envoi-cas-general",
        this.densiteenergetique,
        this.acidesgrassat,
        this.sucres,
        this.sodium,
        this.percentPos,
        this.fibres,
        this.proteines,
        this.type
      );
     }
    },
    validateField() {
      
      return this.$refs.form.validate();
      //     alert("ee");
    },
  },
};
</script>

<style lang="scss" scoped></style>
