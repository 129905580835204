<template>
<v-container>
<v-row>
     <v-col cols="12" sm="10" md="4"> 
         <img :src='imgSrc' width="100%" class="cadreGrisNutri"/>
     </v-col>

             
     <v-col cols="12" sm="2" md="4" class=""> 
    <v-progress-circular
      :rotate="-90"
      :size="75"
      :width="10"
      :value="scoreNegPond*100/40"
      color="red darken-1"
    >{{ scoreNegPond}}
    </v-progress-circular>
     <v-progress-circular
      :rotate="-90"
      :size="75"
      :width="10"
      :value="scorePosPond*100/15"
      color="teal"
    >{{ scorePosPond}}
    </v-progress-circular>
    <v-progress-circular
      :rotate="-90"
      :size="75"
      :width="10"
      :value="(score+15)*100/55"
      color="blue-grey"
    >{{ score }}
    </v-progress-circular>
     </v-col>    

</v-row>

</v-container>
</template>

<script>
    export default {
        props: {
            densiteEnergetique  :{
                type : Number,
                default : 0
            },
            graissesSaturees : {
                 type : Number,
                default : 0
            },
            sucres : {
                 type : Number,
                default : 0
            },
            sodium :{
                 type : Number,
                default : 0
            },
            percentPos : {
                  type : Number,
                default : 0
            },
            fibres : {
                  type : Number,
                default : 0
            },
            proteines : {
                type : Number,
                default : 0
            },
            type : {
                type : Number,
                default : 0,
            }
        },
        data()  {
            return {
                densiteGrille : [335,670,1005,1340,1675,2010,2345,2680,3015,3350],
                densiteGrilleBoisson : [0,30,60,90,120,150,180,210,240,270],
                graissesGrille : [1,2,3,4,5,6,7,8,9,10],
                sucreGrille : [4.5,9,13.5,18,22.5,27,31,36,40,45],
                sucreGrilleBoisson : [0,1.5,3,4.5,6,7.5,9,10.5,12,13.5],
                sodiumGrille : [90,180,270,360,450,540,630,720,810,900],
                percentPosGrille : [40,60,80,80,80],
                percentPosGrilleBoisson : [100,40,40,60,60,60,60,60,60,80],
                fibresGrille : [0.9,1.9,2.8,3.7,4.7],
                proteinesGrille : [1.6,3.2,4.8,6.4,8],
                scoreGrille : [0,3,11,19],
                rankGrille : ['A','B','C','D','E'],
                rankImage : ['Nutri-score-A.svg','Nutri-score-B.svg','Nutri-score-C.svg', 'Nutri-score-D.svg','Nutri-score-E.svg']

            }

        },
        computed : {                        
            scoreNegPond() {
                let res=0;
                if (this.type==0 ||this.type==1) {
                    res=this.getPoints(this.densiteEnergetique,this.densiteGrille)
                    + this.getPoints(this.graissesSaturees,this.graissesGrille)
                    + this.getPoints(this.sucres,this.sucreGrille)
                    + this.getPoints(this.sodium,this.sodiumGrille);
                }else if (this.type==2) {
                    res= this.getPoints(this.densiteEnergetique,this.densiteGrilleBoisson)
                    + this.getPoints(this.graissesSaturees,this.graissesGrille)
                    + this.getPoints(this.sucres,this.sucreGrilleBoisson)
                    + this.getPoints(this.sodium,this.sodiumGrille);
                }
                return res;
            },           
            scorePosPond() {
                let res=0
                if (this.type==0) {
                    res = this.scorePourcentPosPond + this.getPoints(this.fibres,this.fibresGrille);                   
                    if (this.scoreNegPond<11 || this.scorePourcentPosPond==5) {
                        res = res+  this.scoreProteines
                    }                     
                }else if (this.type==1) {
                    res= this.scorePourcentPosPond
                    + this.getPoints(this.fibres,this.fibresGrille)
                    + this.scoreProteines;                    
                } if (this.type==2) {
                     res= this.scorePourcentPosPond
                        + this.getPoints(this.fibres,this.fibresGrille)
                        + this.scoreProteines;
                }
                return res;
            },
            scorePourcentPosPond() {
                let res=0;
                if (this.type==0||this.type==1) {
                    res= this.getPoints(this.percentPos,this.percentPosGrille); 
                } else if (this.type==2) {
                    res= this.getPoints(this.percentPos,this.percentPosGrilleBoisson);
                }
                return res;
            },
            scoreProteines() {
                return this.getPoints(this.proteines,this.proteinesGrille);

            },
            score() {
                let total=0;
                total =  this.scoreNegPond - this.scorePosPond;                
                return total;
            },
            lettre() {
                let rank= this.getPoints(this.score,this.scoreGrille);
                return this.rankGrille[rank];

            },
            imgSrc() {
                let rank= this.getPoints(this.score,this.scoreGrille);
                return this.rankImage[rank];
            }

        },
        methods : {
            getPoints(val,grille) {
              
                // alert(val + " / "+grille);
                let point=0;
                grille.forEach((elem, index) => {
                    if (val>elem) {point=index+1}
                });
                return point;
            }
        }
        
    
    }
</script>

<style lang="scss" scoped>
    .cadreGrisNutri {
            border:  0.5em solid #76787B;
            border-radius: 50px;
    }
</style>